import React, { useEffect, useState } from "react"
import * as styles from "./simple-hero.module.scss"
import BgImg from "gatsby-background-image"
const SimpleHero = ({ banner, tag, category }) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const { background_image, heading } = banner.elements
  if (background_image && background_image.value.length) {
    return (
      <BgImg
        fluid={background_image.value[0].fluid}
        alt={background_image.value[0].description}
        className={`${styles.simpleHero} ${styles.bannerContainer} simple-hero-override`}
      >
        <div className={`PrimaryColorBg ${styles.overlay} `}></div>
        <section
          className={`container ${styles.bannerContent}`}
          style={{ transform: `translateY(-${offset * 0.25}px)` }}
        >
          <div className={styles.bannerContent}>
            {tag || category ? (
              <h1>{tag ? tag : `Articles tagged with ${category}`}</h1>
            ) : (
              <h1>{heading.value.toUpperCase()}</h1>
            )}
          </div>
        </section>
      </BgImg>
    )
  } else {
    return (
      <section className={`${styles.simpleHeroTextOnly} text-only-override`}>
        <div className={`container ${styles.bannerContent}`}>
          {tag || category ? (
            <h1>{tag ? tag : category}</h1>
          ) : (
            <h1 className={styles.heading}>{heading.value.toUpperCase()}</h1>
          )}
        </div>
      </section>
    )
  }
}

export default SimpleHero
